import React, { InputHTMLAttributes } from 'react'

const Input: React.FC<
  {
    prefix?: string
    placeholder?: string
  } & InputHTMLAttributes<HTMLInputElement>
> = ({ prefix, placeholder, ...other }) => {
  return (
    <div
      className={
        'flex-1 first:ml-0 last:mr-0 md:mx-2 mb-4 bg-black/[0.10] rounded-xl p-4 text-2xl flex'
      }>
      <div className={'flex-1 flex'}>
        <div className={'opacity-25'}>{prefix}</div>
        <input
          className={
            'overflow-x-auto whitespace-nowrap w-full bg-white/0 placeholder:text-black placeholder:opacity-25 border-0 focus:border-0 outline-none focus:outline-none'
          }
          type={'text'}
          placeholder={placeholder}
          {...other}
        />
      </div>
    </div>
  )
}

export default Input
