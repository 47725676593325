import React, { useState } from 'react'
import './App.css'
import { Player, Controls } from '@lottiefiles/react-lottie-player'

import Beecin from './Lottie/beecin.json'
import { Notification } from './components'
import { DuplicateIcon } from '@heroicons/react/outline'
import Input from './components/atoms/Input'

function App() {
  const [input, setInput] = useState({
    username: '',
    endpoint: '',
    title: '',
    body: '',
  })

  const encodedInput = {
    username: encodeURIComponent(
      input.username === '' ? 'username' : input.username,
    ),
    endpoint: encodeURIComponent(
      input.endpoint === '' ? 'endpoint' : input.endpoint,
    ),
    title: encodeURIComponent(input.title === '' ? 'Yo' : input.title),
    body: encodeURIComponent(input.body === '' ? 'Dawg' : input.body),
  }

  const [lang, setLang] = useState<'js' | 'py' | 'curl'>('js')

  const handleCopy = () => {
    let copyText = ''

    switch (lang) {
      case 'js':
        copyText = `fetch(“https://beec.in/${encodedInput.username}/${encodedInput.endpoint}?title=${encodedInput.title}&body=${encodedInput.body}”)`
        break
      case 'py':
        copyText = `requests.get('https://beec.in/${encodedInput.username}/${encodedInput.endpoint}?title=${encodedInput.title}&body=${encodedInput.body}')`
        break
      case 'curl':
        copyText = `curl --location --request GET 'beec.in/${encodedInput.username}/${encodedInput.endpoint}?title=${encodedInput.title}&body=${encodedInput.body}'`
        break
    }

    navigator.clipboard.writeText(copyText)
  }

  const handleInput = (
    text: string,
    key: 'username' | 'endpoint' | 'title' | 'body',
  ) => {
    setInput({
      ...input,
      [key]: text,
    })
  }

  const handleChangeLang = () => {
    switch (lang) {
      case 'js':
        setLang('py')
        break
      case 'py':
        setLang('curl')
        break
      case 'curl':
        setLang('js')
        break
    }
  }

  const handleTest = async () => {
    await fetch(
      `https://beec.in/${encodedInput.username}/${encodedInput.endpoint}?title=${encodedInput.title}&body=${encodedInput.body}`,
    )
  }

  const renderExample = () => {
    switch (lang) {
      case 'js':
        return (
          <div className={'overflow-x-auto whitespace-nowrap flex-1'}>
            <span className={'text-primary'}>fetch</span>
            (“https://beec.in/
            <span className={'text-primary'}>{encodedInput.username}</span>/
            <span className={'text-primary'}>{encodedInput.endpoint}</span>
            ?title=
            <span className={'text-primary'}>{encodedInput.title}</span>
            &body=
            <span className={'text-primary'}>{encodedInput.body}</span>
            ”)
          </div>
        )
      case 'py':
        return (
          <div className={'overflow-x-auto whitespace-nowrap flex-1'}>
            requests.
            <span className={'text-primary'}>get</span>
            ('https://beec.in/
            <span className={'text-primary'}>{encodedInput.username}</span>/
            <span className={'text-primary'}>{encodedInput.endpoint}</span>
            ?title=
            <span className={'text-primary'}>{encodedInput.title}</span>
            &body=
            <span className={'text-primary'}>{encodedInput.body}</span>
            ')
          </div>
        )
      case 'curl':
        return (
          <div className={'overflow-x-auto whitespace-nowrap flex-1'}>
            curl --location --request
            <span className={'text-primary'}> GET </span>
            'beec.in/
            <span className={'text-primary'}>{encodedInput.username}</span>/
            <span className={'text-primary'}>{encodedInput.endpoint}</span>
            ?title=
            <span className={'text-primary'}>{encodedInput.title}</span>
            &body=
            <span className={'text-primary'}>{encodedInput.body}</span>'
          </div>
        )
    }
  }

  return (
    <div className="App relative">
      <Player autoplay loop src={Beecin} style={{ height: '20vh' }} />
      <div className={'max-w-5xl mx-auto px-4 h-screen'}>
        <Notification
          title={'About the app'}
          body={
            'The easiest way to develop notifications for your mobile device.'
          }
        />

        <Notification
          title={'1. Download the app'}
          body={'Click here to get the app and create an account.'}
          icon
        />

        <Notification
          title={'2. Make a request'}
          body={
            'Send notifications to your device simply with an API request.'
          }>
          <div className={'bg-white/[0.16] rounded-xl p-4 text-2xl flex'}>
            {renderExample()}
            <div
              className={'flex items-center ml-4 transition-all select-none'}>
              <div
                className={'mr-4 opacity-50 hover:opacity-100 cursor-pointer'}
                onClick={handleChangeLang}>
                {lang}
              </div>
              <DuplicateIcon
                className="h-6 w-6 opacity-50 hover:opacity-100 cursor-pointer"
                onClick={handleCopy}
              />
            </div>
          </div>
        </Notification>

        <div
          style={{ fontFamily: 'mundial-bold' }}
          className={
            'px-4 pt-8 pb-4 mb-4 max-w-5xl mx-auto bg-white rounded-3xl text-black z-1 md:hidden'
          }>
          <div className={'m-auto mb-4 text-6xl text-center'}>try it</div>

          <div className={''}>
            <Input
              prefix={'/'}
              placeholder={'username'}
              onChange={e => handleInput(e.target.value, 'username')}
              value={input.username}
            />
            <Input
              prefix={'/'}
              placeholder={'endpoint'}
              onChange={e => handleInput(e.target.value, 'endpoint')}
              value={input.endpoint}
            />
            <Input
              prefix={'?'}
              placeholder={'title'}
              onChange={e => handleInput(e.target.value, 'title')}
              value={input.title}
            />
            <Input
              prefix={'&'}
              placeholder={'body'}
              onChange={e => handleInput(e.target.value, 'body')}
              value={input.body}
            />
          </div>

          <button
            className={'bg-green/[0.20] rounded-xl p-4 text-2xl flex w-full'}
            onClick={handleTest}>
            <div className={'flex-1 text-center text-green'}>send</div>
          </button>
        </div>
        <div className={'h-4'} />
      </div>

      <div
        style={{ fontFamily: 'mundial-bold' }}
        className={
          'px-4 pt-8 pb-4 max-w-5xl mx-auto bg-white rounded-t-3xl text-black sticky bottom-0 z-1 hidden md:block'
        }>
        <div className={'m-auto mb-4 text-6xl text-center'}>try it</div>

        <div className={'flex'}>
          <Input
            prefix={'/'}
            placeholder={'username'}
            onChange={e => handleInput(e.target.value, 'username')}
            value={input.username}
          />
          <Input
            prefix={'/'}
            placeholder={'endpoint'}
            onChange={e => handleInput(e.target.value, 'endpoint')}
            value={input.endpoint}
          />
          <Input
            prefix={'?'}
            placeholder={'title'}
            onChange={e => handleInput(e.target.value, 'title')}
            value={input.title}
          />
          <Input
            prefix={'&'}
            placeholder={'body'}
            onChange={e => handleInput(e.target.value, 'body')}
            value={input.body}
          />
        </div>

        <button
          className={'bg-green/[0.20] rounded-xl p-4 text-2xl flex w-full'}
          onClick={handleTest}>
          <div className={'flex-1 text-center text-green'}>send</div>
        </button>
      </div>
    </div>
  )
}

export default App
