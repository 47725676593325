import React from "react";
import { Images } from "../../styles";

const Notification: React.FC<{
  title: string;
  body: string;
  icon?: boolean;
  children?: any;
}> = ({ title, body, icon = false, children }) => {
  return (
    <div className={"bg-white/[0.16] mb-6 p-4 rounded-xl"}>
      <div className={"flex"}>
        <div className={"flex-1"}>
          <div
            className={"text-2xl opacity-75"}
            style={{ fontFamily: "mundial-bold" }}
          >
            {title}
          </div>
          <div
            className={"text-2xl opacity-75"}
            style={{ fontFamily: "mundial-light" }}
          >
            {body}
          </div>
        </div>
        {icon && (
          <div className={"flex items-center ml-4"}>
            <img
              className={"w-14 h-14 rounded-xl"}
              src={Images.AppIcon}
              alt="Beec.in App Icon"
            />
          </div>
        )}
      </div>
      {children && <div className={"mb-4"} />}
      {children}
    </div>
  );
};

export default Notification;
